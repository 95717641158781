<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="blue"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
      color="blue darken-2"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

        <!--
        <v-img v-else>
          src="/assets/dev/logo-icon.png"
        </v-img>
        -->
        <v-img v-else width="1" style="transform: scale(0.6)" src="@/assets/dev/logo-icon.png" />

    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light white--text"
      v-text="$route.name"
    />

    <v-spacer />

    <!--
    <v-btn class="ml-2" min-width="0" text to="/">
      <v-icon>mdi-home</v-icon>
    </v-btn>
    -->

    <v-spacer />


    <!-- <v-text-field
      :label="$t('search')"
      color="white"
      hide-details
      style="max-width: 165px;"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        v-slot:append-outer
      >
        <v-btn
          class="mt-n2"
          elevation="1"
          fab
          small
          color="blue darken-2"
        >
          <v-icon class="white--text">mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <div class="mx-3" />

    -->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge v-if="non_readed_notifs.length > 0" color="red" overlap bordered>
            <template v-slot:badge>
              <span>{{non_readed_notifs.length}}</span>
            </template>
            <v-icon class="white--text">mdi-bell</v-icon>
          </v-badge>
          <v-icon v-else class="white--text">mdi-bell</v-icon>

        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
        v-if="non_readed_notifs.length > 0"
      >
        <div>
          <app-bar-item v-for="(n, i) in non_readed_notifs" :key="`item-${i}`">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{n.bridgeName}}</v-list-item-title>
                {{n.deviceId}} - {{n.measurementTime}} - {{n.status_tag}}
              </v-list-item-content>
            </v-list-item>
          </app-bar-item>
        </div>
      </v-list>

      <v-list
        :tile="false"
        nav
        v-else
      >
        <app-bar-item>No hay alertas sin leer</app-bar-item>
      </v-list>

    </v-menu>

    <v-btn
      class="ml-2 white--text"
      min-width="0"
      text
      to="/dashboard/user"
    >
      <v-icon>mdi-account</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({

    }),

    computed: {
      ...mapState({
        non_readed_notifs: state => state.non_readed_notifs,
        notifications: state => state.notifications,
	drawer: state => state.drawer,
      }),
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
    },
  }
</script>
